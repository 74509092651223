@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Squada+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Lobster&family=Squada+One&display=swap');
* {
    box-sizing: border-box;
}
.container {
    position: relative;
}
.main {
    background-image: url(../image/dashboard_bg.jpg);
    width: 100%;
    height: 750px;
    background-size: cover;
    position: relative;
}
.header {
    position: relative;
    width: 100%;
    height: 100px;
}
.brand_area {
    display: flex;
    position: absolute;
    left: 30px;
    width: auto;
    height: 100px;
    margin: auto 0;
}
.brand_area .logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
}
.title {
    color: brown;
    margin: auto 0;
    font-family: "Bungee", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.navbar {
    display: flex;
    position: absolute;
    right: 30px;
    height: 100px;
    width: auto;
}
.navbar ul {
    display: flex;
    list-style: none;
    margin: auto 0;
}
.navbar ul li {
    padding: 10px;
    font-weight: 500;
    font-size: 30px;
    color: brown;
    font-family: "Squada One", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.navbar ul li:hover {
    scale: 1.2;
}
.content1 {
    height: auto;
    width: 30%;
    margin: 0 auto;
}
.car_img {
    width: 100%;
    height: auto;
}
.slogan {
    text-align: center;
    color: brown;
    margin: 0;
    font-family: "Lobster", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.button {
    margin: 40px auto;
    width: 60%;
    height: auto;
}
.two_button {
    display: flex;
    justify-content: center;
}
.one_button {
    display: flex;
    justify-content:center;
}
.download_btn, .login_btn, .delete_btn {
    width: 250px;
    height: 40px;
    border-radius: 30px;
    font-size: 20px;
    margin: 15px;
    font-family: "Squada One", sans-serif;
    font-weight: 400;
    font-style: normal;
    box-shadow: 2px 2px black;
}
.two_button button:hover,.one_button button:hover {
    background-color: brown;
    color: white;
}
.download_btn {
    border: 3px solid brown;
}
.login_btn, .delete_btn {
    background-color: brown;
    color: white;
}
.login_btn:hover, .delete_btn:hover {
    scale: 1.1;
}
.footer {
    width: 100%;
    height: auto;
    display: grid;
    background-color: rgb(47, 47, 47);
    grid-template-columns: 1fr 1fr 1fr;
    color: white;
}
.footer1 {
    padding: 30px;
    text-align: center;
}
.footer1 p {
    font-size: 25px;
    font-family: "Squada One", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.google {
    width: 100px;
    height: 60px;
}
.condition {
    list-style: none;
}
.condition li {
    font-size: 30px;
    margin-top: 5px;
    font-family: "Squada One", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.footer1 i {
    margin: 10px;
    font-size: 25px;
}
.delete_comp {
    position: absolute;
    top: 90px;
    right: 0;
    height: auto;
    width: 30%;
    padding: 0;
    margin-right: 4px;
}
@media screen and (max-width: 500px) {
    .navbar {
        position: absolute;
        margin-top: 70px;
        left: 0;
    }
    .navbar ul {
        display:block;
        list-style: none;
    }
    .navbar ul li {
        margin-top: 5px;
    }
    .footer {
        width: 100%;
        display: block;
    }
}
@media screen and (max-width: 800px) {
    .footer {
        display: block;
    }
    .footer1 {
        padding: 0 100px;
    }
}