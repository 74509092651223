* {
    box-sizing:border-box;
}
.icon {
    font-size: 25px;
    position: absolute;
    left: 15px;
    top: 15px;
}
.form_holder {
    width: 100%;
    height: 400px;
    background-color:wheat;
    border-radius: 20px;
    padding-top: 80px;
    text-align: center;
    box-shadow: 4px 4px grey;
}
form {
    height: auto;
}
.head {
    font-size: 25px;
    padding: 10px
}
input {
    margin-top: 30px;
    width: 260px;
    height: 35px;
    border-radius: 25px;
    padding-left: 10px;
    border: 0px;
}
.submit {
    background-color: brown;
    margin-top: 25px;
    color: white;
}